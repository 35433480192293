/**
 * Application entry point
 */

// Load application styles
import 'styles/index.scss';

// ================================
// START YOUR APP HERE
// ================================

window.onload = () => {

  /**
   * Navigation Active Class
   */
  (() => {
    const activeIndex = Number(document.querySelector('p.hidden--nav-index').textContent);
    const navItems = document.querySelectorAll('.nav a');
    navItems[activeIndex].classList.add('active');
  })();

  /**
   * Mobile Navigation
   */
  (() => {
    const menuButton = document.querySelector('#menu-button');

    document.body.addEventListener('click', e => {
      if (e.target.getAttribute('data-menu') === 'open') {
        e.preventDefault();
        changeAttributes('close');
        toggleMenu(true);
        menuButton.classList.toggle('on-menu');
      } else if (e.target.getAttribute('data-menu') === 'close') {
        e.preventDefault();
        changeAttributes('open');
        toggleMenu(false);
        menuButton.classList.toggle('on-menu');
      }
    });

    function changeAttributes(attribute) {
      const needed = document.querySelectorAll('[data-menu]');
      needed.forEach(elem => elem.setAttribute('data-menu', attribute));
    }

    function toggleMenu(state) {
      const nav = document.querySelector('.mobile-nav');
      state ? nav.classList.add('on-menu') : nav.classList.remove('on-menu');
    }
  })()
}
